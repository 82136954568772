var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 pa-0 ma-0 tgl-page-header"},[_c('tgl-breadcrumbs',{attrs:{"items":_vm.breadcrumbs}}),(_vm.$store.getters.getPermission('slots_remove'))?_c('slot-actions'):_vm._e()],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 pa-0 ma-0"},[_c('v-tabs',[_c('v-tab',{attrs:{"to":{
              name: 'slotDetails',
              params: {
                device: _vm.$route.params.device,
                slot: _vm.$route.params.slot,
              },
            }}},[_vm._v(_vm._s(_vm.$t("device.slot.slotTab.detail")))]),(_vm.$store.getters.getPermission('slot_edit'))?_c('v-tab',{attrs:{"to":{
              name: 'slotConfiguration',
              params: {
                device: _vm.$route.params.device,
                slot: _vm.$route.params.slot,
              },
            }}},[_vm._v(_vm._s(_vm.$t("device.slot.slotTab.configuration")))]):_vm._e(),_c('v-tab',{attrs:{"to":{
              name: 'slotCalibrator',
              params: {
                device: _vm.$route.params.device,
                slot: _vm.$route.params.slot,
              },
            }}},[_vm._v(_vm._s(_vm.$t("device.slot.slotTab.calibrator")))]),_c('v-tab',{attrs:{"to":{
              name: 'slotMonitoring',
              params: {
                device: _vm.$route.params.device,
                slot: _vm.$route.params.slot,
              },
            },"disabled":""}},[_vm._v(_vm._s(_vm.$t("device.slot.slotTab.monitoring")))]),_c('v-tab',{attrs:{"to":{
              name: 'slotEventListeners',
              params: {
                device: _vm.$route.params.device,
                slot: _vm.$route.params.slot,
              },
            }}},[_vm._v(_vm._s(_vm.$t("device.slot.slotTab.eventListener")))]),_c('v-tab',{attrs:{"to":{
              name: 'slotStreaming',
              params: {
                device: _vm.$route.params.device,
                slot: _vm.$route.params.slot,
              },
            },"disabled":""}},[_vm._v(_vm._s(_vm.$t("device.slot.slotTab.streaming")))])],1),_c('router-view')],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }