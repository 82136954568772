<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 pa-0 ma-0 tgl-page-header">
          <tgl-breadcrumbs :items="breadcrumbs" />
          <slot-actions v-if="$store.getters.getPermission('slots_remove')" />
        </div>
      </div>
      <div class="row">
        <div class="col-12 pa-0 ma-0">
          <v-tabs>
            <v-tab
              :to="{
                name: 'slotDetails',
                params: {
                  device: $route.params.device,
                  slot: $route.params.slot,
                },
              }"
              >{{ $t("device.slot.slotTab.detail") }}</v-tab
            >
            <v-tab
              v-if="$store.getters.getPermission('slot_edit')"
              :to="{
                name: 'slotConfiguration',
                params: {
                  device: $route.params.device,
                  slot: $route.params.slot,
                },
              }"
              >{{ $t("device.slot.slotTab.configuration") }}</v-tab
            >
            <v-tab
              :to="{
                name: 'slotCalibrator',
                params: {
                  device: $route.params.device,
                  slot: $route.params.slot,
                },
              }"
              >{{ $t("device.slot.slotTab.calibrator") }}</v-tab
            >
            <v-tab
              :to="{
                name: 'slotMonitoring',
                params: {
                  device: $route.params.device,
                  slot: $route.params.slot,
                },
              }"
              disabled
              >{{ $t("device.slot.slotTab.monitoring") }}</v-tab
            >
            <v-tab
              :to="{
                name: 'slotEventListeners',
                params: {
                  device: $route.params.device,
                  slot: $route.params.slot,
                },
              }"
              >{{ $t("device.slot.slotTab.eventListener") }}</v-tab
            >
            <v-tab
              :to="{
                name: 'slotStreaming',
                params: {
                  device: $route.params.device,
                  slot: $route.params.slot,
                },
              }"
              disabled
              >{{ $t("device.slot.slotTab.streaming") }}</v-tab
            >
          </v-tabs>
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TglBreadcrumbs from "../../components/UI/TglBreadcrumbs";
import SlotActions from "../../components/Slots/Actions";

export default {
  components: {
    TglBreadcrumbs,
    SlotActions,
  },
  data() {
    return {
      device: null,
      slot: null,
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          label: "Dispositivos",
          disabled: false,
          to: { name: "devices" },
        },
        {
          label: this.device ? this.device.alias : this.$route.params.device,
          disabled: false,
          to: {
            name: "deviceDetails",
            params: { device: this.$route.params.device },
          },
        },
        {
          label: "Slots",
          disabled: false,
          to: {
            name: "deviceSlots",
            params: { device: this.$route.params.device },
          },
        },
        {
          label: this.slot ? this.slot.alias : this.$route.params.slot,
          disabled: false,
          to: {
            name: "slotProfile",
            params: {
              device: this.$route.params.device,
              slot: this.$route.params.slot,
            },
          },
        },
        {
          label: this.$t(this.$route.meta.title.toLowerCase()),
          disabled: true,
          to: { name: "devices" },
        },
      ];
    },
  },
  methods: {
    getDevice() {
      this.$store
        .dispatch("device/getDevice", this.$route.params.device)
        .then((data) => {
          this.device = data;
        });
    },
    getSlot() {
      this.$store
        .dispatch("slot/getSlot", {
          device: this.$route.params.device,
          slot: this.$route.params.slot,
        })
        .then((data) => {
          this.slot = data;
        });
    },
  },
  mounted() {
    this.getDevice();
    this.getSlot();
  },
};
</script>